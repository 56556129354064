/**
 * Copyright © 2019 Johnson & Johnson
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 * http://www.apache.org/licenses/LICENSE-2.0
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import React from "react";
import { Helmet } from "react-helmet";
import Page from "./Page";
import Gdpr from "src/components/Gdpr";

export default class IndexPage extends React.Component {
  render() {
    if (typeof window !== "undefined") {
      return (
        <div
          dangerouslySetInnerHTML={{
            __html: "https://github.com/facebook/react/issues/10923"
          }}
        />
      );
    }
    return (
      <React.Fragment>
        <Page />
        <Helmet>
          <html lang= "nl" className="no-js" />
          <body className="nl-BE" />
          <script innerHTML='digitalData = [{"event":"Page Loaded","page":{"analyticsId":"UA-91649088-42",
                    "brandName":"Imodium",
                    "country":"nl",
                    "flattened":true,
                    "hostname":"www.imodium.nl",
                    "language":"nl",
                    "siteTier":"low",
              }}];'
          />
          <script
            innerHTML="(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
                  new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
                  j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
                  'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
                  })(window,document,'script','globalDataLayer','GTM-PJSTS9Z');"
          />
          <meta charSet="utf-8" />
          <meta
            name="viewport"
            content="width=device-width,initial-scale=1.0"
          />
          <title>
            Waarom krijg ik last van mijn maag wanneer ik zenuwachtig ben
          </title>
          <meta
            name="description"
            content="Diarree kan worden veroorzaakt door angst. Als je weet dat je hieraan lijdt, kan je IMODIUM® Instant smelttabletten je verder helpen."
          />
          <meta
            property="og:title"
            content="Waarom krijg ik last van mijn maag wanneer ik zenuwachtig ben"
          />
          <meta property="og:type" content="other" />
          <meta
            property="og:url"
            content="https://www.imodium.nl/veelgestelde-vragen-diarree/waarom-krijg-ik-last-van-mijn-maag-wanneer-ik-zenuwachtig-ben/"
          />
          <meta property="og:image" content />
          <meta property="og:site_name" content="IMODIUM®" />
          <meta
            property="og:description"
            content="Diarree kan worden veroorzaakt door angst. Als je weet dat je hieraan lijdt, kan je IMODIUM® Instant smelttabletten je verder helpen."
          />
          <link rel="icon" href="/assets/img/favicon.ico" type="image/x-icon" />
          <link
            rel="shortcut icon"
            href="/assets/img/favicon.ico"
            type="image/x-icon"
          />

          <link rel="stylesheet" href="/assets/css/screen.css" />

          <script
            type="text/javascript"
            innerHTML="function OptanonWrapper() { }"
          />
          <script
            type="text/javascript"
            innerHTML="
if(window.top !== window.self || top != self){window.top.location=window.self.location;top.location.replace(location);}
"
          />
          <script src="/assets/js/vendor/modernizr-2.6.1.min.js" />
        </Helmet>
        <Gdpr />
        ;
      </React.Fragment>
    );
  }
}
